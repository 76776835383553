import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
<div>
	<div className="section-footer">
		<div className="footer-holder">
			<div className="sec-footer-top">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<h3 className="text-uppercase footer--title">
								{window.Configs.domain}
							</h3>
							<p>
								{window.Configs.description}
							</p>
						</div>
						<div className="col-md-3">
							<h3 className="text-uppercase footer--title">
								get started
							</h3>
							<ul className="list-unstyled f-a-links">								
								<li><Link to='/partner'>Partner With Us</Link></li>
								<li><a href={'/apply'}>Apply</a></li>
								<li><Link to='/referral'>Referral</Link></li>
								<li><Link to='/apps'>Apps</Link></li>	
								<li><Link to='/developers'>Developers</Link></li>	
							</ul>
						</div>
						<div className="col-md-3">
							<h3 className="text-uppercase footer--title">
								company
							</h3>
							<ul className="list-unstyled f-a-links f-a-links-mrgBtm">
								<li><Link to='/about'>About</Link></li>
								<li>
									<a href={'https://www.contrib.com/home/contact'} target="_blank" rel="noopener noreferrer">
										Contact
									</a>
								</li>								
								<li><Link to='/terms'>Terms</Link></li>
								<li><Link to='/cookie'>Cookie Policy</Link></li>
								<li><Link to='/privacy'>Privacy</Link></li>					
							</ul>
						</div>
						<div className="col-md-3">
							<h3 className="text-uppercase footer--title">
								partners
							</h3>
							<a href="https://goo.gl/R4U8sH">
								<img className="img-fluid ctb-banner" src="https://cdn.vnoc.com/banner/banner-ctb-earn-ctb-tokens.png" alt="Crypto Contrib" title="Crypto Contrib"></img>
							</a>
							<h3 className="text-uppercase footer--title mt-3">
								Socials
							</h3>
							<ul className="list-inline socials-ul mt-3 pl-2">
								<li className="list-inline-item">
									<a title="twitter" className="icon-button twitter" href="https://twitter.com/contrib">
										<i className="fab fa-twitter" aria-hidden="true"></i>
										<span></span>
									</a>
								</li>
								<li className="list-inline-item">
									<a title="facebook" className="icon-button facebook" href="https://www.facebook.com/Contrib.Official">
										<i className="fab fa-facebook-f" aria-hidden="true"></i>
										<span></span>
									</a>
								</li>
								<li className="list-inline-item">
									<a title="google-plus" className="icon-button google-plus" href="https://plus.google.com/u/1/105733839510740941811/posts">
										<i className="fab fa-google-plus-g" aria-hidden="true"></i>
										<span></span>
									</a>

								</li>

								<li className="list-inline-item">
									<a title="linkedin" className="icon-button linkedin" href="http://www.linkedin.com/company/contrib-com">
										<i className="fab fa-linkedin-in" aria-hidden="true"></i>
										<span></span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="sec-footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6 f-a-links">
									© 2020 <span className="text-capitalize">{window.Configs.domain}</span>. All Rights Reserved. 
								</div>
								<div className="col-md-6">
									<ul className="list-inline text-right f-a-links">
										<li className="list-inline-item">
											<Link to='/about'>About</Link>
										</li>
										<li className="list-inline-item">
											<Link to='/terms'>Terms</Link>
										</li>
										<li className="list-inline-item">
											<Link to='/privacy'>Privacy</Link>
										</li>											
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id={'beforeyougo'}>
	</div>
</div>
)
  
export default Footer;
