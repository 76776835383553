import React from 'react';

const About = () => (
	<div>
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="page-container">
						<div className="page-inner text-center">
							<h2 className="text-center">About Us</h2>
							<p><span className="text-capitalize">{window.Configs.domain}</span> is currently part of the Contrib platform</p>
							<p className="mb-5">Contrib is a transparent Platform-as-a-Service specifically designed to utilize a sustainable and socially responsible business network model that turns valuable domain assets into successful operating companies.</p>
							<h3 className="text-center vmsion">Our Vision</h3>
							<p className="mb-5">We envision people around the world with complementary skills, passion, time and resources coworking online building sustainable electronic businesses, part-time. Our focus will continue to support <span className="text-capitalize">{window.Configs.domain}</span> members with a system to grow and learn new skills, help build a company from scratch using the worlds best assets, and to contribute with other great, like-minded and talented people.</p>
							<h3 className="text-center vmsion">Our Story</h3><p>Contrib was created out of necessity to build, manage and monetize thousands of premium Urls like <span className="text-capitalize">{window.Configs.domain}</span> acquired over the 18 years of its parent company, GV, llc. Premium assets such as Streaming.com, Staffing.com, Linked and others were losing opportunity value so we built a model and technology to leverage the opportunities and offer it to our visitors.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default About
