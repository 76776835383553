import React, { Component } from 'react'; 

class Apply extends Component { render() { return (
<div className="contact-container">
    <div className="container"> {/* Start:: Staffing Form Text Content */}
        <div className="row page-container">
            <div className="col-lg-6 bg-left-form">
                <div className="bg-left-overlay" />
                <div className="left-content-main">
                    <h1 className="title-left mb-3">
                    Submit Team Application
                    </h1>
                    <p className="desc-left mb-4"> When you submit your registration, you can quickly join the <span />. team and take part in micro tasks and be paid in services fees, equity or performance equities. </p>
                    <div className="box-steps p-3 mb-3">
                        <div className="media"> <img className="mr-3 align-self-center" src="https://cdn.vnoc.com/icons/submit-application.png" alt="" width={40} />
                            <div className="media-body">
                                <div className="steps-header-title d-block fnt-500 mb-2"> Submit an Application </div>
                                <div className="d-block"> <small>
                            You will receive an email when we approve your application.
                            </small> </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-steps p-3">
                        <div className="media"> <img className="mr-3 align-self-center" src="https://cdn.vnoc.com/icons/handshake.png" alt="" width={40} />
                            <div className="media-body">
                                <div className="steps-header-title d-block fnt-500 mb-2"> Start working on Tasks and Requests </div>
                                <div className="d-block"> <small>
                            Make money by getting equity or pay per performance for tasks rendered and service requests fulfilled.
                            </small> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> {/* End */} {/* Start:: Loading */}
            <div className="col-lg-6 align-self-center d-none" id="staffing_solo_loading">
                <div className="text-center"> <img src="https://cdn.vnoc.com/icons/loader-circle-outline.gif" alt=""/>
                    <div className="d-block small"> Please wait . . . </div>
                </div>
            </div> {/* End */} {/* Start:: First Step */}
            <div className="col-lg-6 align-self-center" id="staffing_step1">
                <div className="bg-form-content">
                    <div className="note note-danger d-none" id="staffing_warning1">
                        <div className="note-header"> <i className="fas fa-exclamation-circle" aria-hidden="true" /> Error </div>
                        <div className="note-body"> <small>
                        Please fill-in all required fields (<span className="text-danger">*</span>).
                        </small> </div>
                    </div>
                    <h3 className="fnt-300 mb-3 text-primary">
                    Apply Today for <span className="text-capitalize">{window.Configs.domain}<span /></span>
                    </h3>
                    <div className="form-group">
                        <label>Email Address <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="staffing_initialemail" /> </div> <span  className="btn btn-primary btn-block pulse-btn" id="staffing_btn_1">
                    Apply Today
                    <i className="fas fa-arrow-right ml-2" />
                    </span> 
		      <div className="mt-5 d-none">
				<small>
					Note: If Form does'nt work please refresh the page.					
				</small>
		      </div>
		      </div>		      
            </div> {/* End */} {/* Start:: Second Step */}
            <div className="col-lg-6 align-self-center d-none" id="staffing_step2">
                <div className="bg-form-content">
                    <h3 className="fnt-300 mb-3 text-primary">
                    Apply Today for <span className="text-capitalize">{window.Configs.domain}<span /></span>
                    </h3>
                    <div className="note note-danger d-none" id="staffing_warning2">
                        <div className="note-header"> <i className="fas fa-exclamation-circle" aria-hidden="true" /> Error </div>
                        <div className="note-body"> <small>
                        Please fill-in all required fields (<span className="text-danger">*</span>).
                        </small> </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            First Name
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_firstname" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Last Name
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_lastname" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Email
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_email" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Website
                            </small> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_website" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Country
                            </small> <span className="text-danger">*</span> </label>
                                <select className="form-control form-control-sm" id="staffing_country">
									{window.Configs.countriesarray.map(country=><option key={country.country_id} value={country.country_id}>{country.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            City
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_city" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Password
                            </small> <span className="text-danger">*</span> </label>
                                <input type="password" className="form-control form-control-sm" id="staffing_password" /> </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-block mb-2">
                                <label> <small>
                            Confirm Password
                            </small> <span className="text-danger">*</span> </label>
                                <input type="password" className="form-control form-control-sm" id="staffing_password2" /> </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6"> <span  className="btn btn-primary btn-block" id="staffing_btn_2">
                        Next
                        <i className="fas fa-arrow-right ml-2" />
                        </span> </div>
                    </div>
                </div>
            </div> {/* End */} {/* Start:: Third Step */}
            <div className="col-lg-6 align-self-center d-none" id="staffing_step3">
                <div className="bg-form-content">
                    <h3 className="fnt-300 mb-3 text-primary">
                    Apply Today for <span className="text-capitalize">{window.Configs.domain}<span /></span>
                    </h3>
                    <div className="note note-danger d-none" id="staffing_warning3">
                        <div className="note-header"> <i className="fas fa-exclamation-circle" aria-hidden="true" /> Error </div>
                        <div className="note-body"> <small>
                        Please fill-in all required fields (<span className="text-danger">*</span>).
                        </small> </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="d-block mb-2">
                                <label> <small>
                            Team Role
                            </small> <span className="text-danger">*</span> </label>
                                <select className="form-control form-control-sm" id="staffing_role">
									{window.Configs.rolesarray.map(role=><option key={role.role_id} value={role.role_id}>{role.role_name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-block mb-2">
                                <label> <small>
                            Resume Link
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_resumeurl" /> </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-block mb-2">
                                <label> <small>
                            Why you should join in our team?
                            </small> <span className="text-danger">*</span> </label>
                                <input type="text" className="form-control form-control-sm" id="staffing_message" /> </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <span  className="btn btn-danger btn-block" id="staffing_back_3"> <i className="fas fa-arrow-left mr-2" /> Back </span>
                        </div>
                        <div className="col-lg-6"> <span  className="btn btn-primary btn-block" id="staffing_btn_3">
                        Next
                        <i className="fas fa-arrow-right ml-2" />
                        </span> </div>
                    </div>
                </div>
            </div> {/* End */} {/* Start:: Fourth Step */}
            <div className="col-lg-6 align-self-center d-none" id="staffing_step4">
                <div className="bg-form-content">
                    <h3 className="fnt-300 mb-3 text-primary">
                    Apply Today for <span className="text-capitalize">{window.Configs.domain}<span /></span>
                    </h3>
                    <div className="note note-danger d-none" id="staffing_warning4">
                        <div className="note-header"> <i className="fas fa-exclamation-circle" aria-hidden="true" /> Error </div>
                        <div className="note-body"> <small>
                        Please fill-in all required fields (<span className="text-danger">*</span>).
                        </small> </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/facebook.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="link to your facebook profile" type="text" className="form-control form-control-sm" id="staffing_facebook" /> 
                                </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                                    (optional)
                                    </span> 
                            </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/linkedin.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="link to your linkedin profile" type="text" className="form-control form-control-sm" id="staffing_linkedin" /> </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/github.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="link to your github profile" type="text" className="form-control form-control-sm" id="staffing_github" /> </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/skype.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="your skype id" type="text" className="form-control form-control-sm" id="staffing_skype" /> </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/yahoo.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="your yahoo id" type="text" className="form-control form-control-sm" id="staffing_yahoo" /> </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/gtalk.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="your gtalk id" type="text" className="form-control form-control-sm" id="staffing_talk" /> </div> 
                                    <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/aol.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="your AOL id" type="text" className="form-control form-control-sm" id="staffing_aol" /> 
                                    </div> <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                            <div className="media"> <img className="mr-3" src="https://d2qcctj8epnr7y.cloudfront.net/images/icons/windows.png" alt="" />
                                <div className="media-body">
                                    <input placeholder="your windows live id" type="text" className="form-control form-control-sm" id="staffing_wlive" /> 
                                    </div> <span className="small ml-3 align-self-center text-secondary">
                            (optional)
                        </span> </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <span  className="btn btn-danger btn-block" id="staffing_back_4"> <i className="fas fa-arrow-left mr-2" /> Back </span>
                        </div>
                        <div className="col-lg-6"> <span className="btn btn-primary btn-block" id="staffing_btn_4">
                        Next
                        <i className="fas fa-arrow-right ml-2" />
                        </span> </div>
                        <input type="hidden" id="staffing_domain" value={window.Configs.domain} /> </div>
                </div>
            </div> {/* End */} {/* Start:: Fifth Step */}
            <div className="col-lg-6 align-self-center d-none" id="staffing_final">
                <div className="bg-form-content">
                    <div className="d-block pb-3 text-center"> <img src="https://cdn.vnoc.com/icons/email.svg" width={100} alt="" /> </div>
                    <h2 className="text-center mb-3">
                    Thank you for your application.
                    </h2>
                    <p className="text-center text-secondary mb-5"> You are now minutes away to joining <span className="text-capitalize"><span /></span> team. </p>
                    <ul className="list-unstyled text-secondary">
                        <li className="mb-3 small"> 1. Click the link in the <span className="text-success">Verification email</span> that we have just sent you. If you still haven't received it, please check your spam inbox. </li>
                        <li className="mb-3 small"> 2. Your verification link will redirect you to our Marketpalce hub where you can login and check out your application status. </li>
                        <li className="small"> 3. You can now take part in actually building out an asset by sending proposals, partnering with brands, joining teams. </li>
                    </ul>
                    <div id="viewcontriblink">Thank You!</div>
                </div>				
            </div>				
        </div>
    </div>   
</div>); } } 

export default Apply;