import React from 'react';
import Topform from './Topform'
import Logo from './components/Logo';
const Topsection = () => (
  <div>
	    <div className="main-container">		
		<div className="domain-green alert-top text-center">
			This domain belongs to the Global Ventures network. We have interesting opportunities for work, sponsors and partnerships.&nbsp; 
			<span className="text-service">
				<a className="label badge-warning" href={'https://domaindirectory.com/servicepage/?domain='+window.Configs.domain} target="_blank" rel="noopener noreferrer">
					&nbsp;Inquire now&nbsp;
				</a>
			</span>
		</div>
		
		<div className="top-section-container bg-overlay">
			<div className="container ts-desc text-center mb-3">	
				<Logo />
				<h5 className="fw-600 mt-3 text-capitalize">				
					{window.Configs.description}
				</h5>
			</div>			
			<div className="container mt-2">				
				<div className="row justify-content-center">					
					<div className="col-md-8 col-box">						
						<div className="row justify-content-center">
							<div className="col-md-12">
								<div className="top-form-content">
									<Topform />						
								</div>
							</div>
						</div>
						<div className="row justify-content-center">
							<div className="col-md-12">
								<h6 className="text-center mt-2 mb-4">
									We envision people around the world with complementary skills, passion, time and resources coworking online with targeted premium assets just like {window.Configs.domain}				
								</h6>						
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
  </div>
)
 
export default Topsection;

