import React from 'react';


class Frame extends React.Component {
  componentDidMount() {
	var loadScript = function(src,b) {
		var tag = document.createElement('script');
		tag.setAttribute("id", "referral-script");
		tag.onload=function(){
			setTimeout(function(){
				var loading = document.getElementById("pageloading");
				loading.remove();
			},1000);
		}
		tag.src = src;
		document.getElementById('script-container').appendChild(tag);
	}
	loadScript('https://www.referrals.com/extension/widget.js?key='+window.Configs.widget_id,function(){
		var loading = document.getElementById("pageloading");
		loading.remove();
	});
  }
  render() {
    return (
      <div className="text-center mt-5 mb-5">
        <div id='script-container' >
			<div id="pageloading">Please wait... Loading...</div>
		</div>
      </div>
    );
  }
}

function Referral() {
  return (
	<div className="Referral">
		<div className="row row justify-content-center">
			<div className="col-md-8">
				<Frame />
			</div>
		</div>		
	</div>
  );
}

export default Referral
