import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import logo from './logo.svg';
import Home from './Home';
import About from './About';
import Apply from './Apply';
import Apps from './Apps';
import Privacy from './Privacy';
import Terms from './Terms';
import Cookie from './Cookie';
import Developers from './Developers';
import Referral from './Referral';
import Partner from './Partner';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import './App.css';

const helmetContext = {};

class App extends Component {
	render() {
	  
    return (
	 <React.Fragment>	
		<HelmetProvider context={helmetContext}>
			<Helmet>
				<link rel="canonical" href={"https://"+window.Configs.domain} />
				<title>{window.Configs.title}</title>
				<meta name="title" content={window.Configs.title} />
				<meta name="description" content={window.Configs.domain+' - '+window.Configs.description} />
				<meta name="keywords" content={window.Configs.keywords} />
				<meta name="author" content={window.Configs.domain} />
				
				<script>
				{`
					
				  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
				  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

				  ga('create', "${window.Configs.account_ga}", "${window.Configs.domain}");
				  ga('send', 'pageview');
				`}
				</script>
				
				<script type="text/javascript">
				{`
					var _paq = window._paq || [];
					_paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
					_paq.push(["setCookieDomain", "*.${window.Configs.domain}"]);
					_paq.push(["setDomains", ["*.${window.Configs.domain}"]]);
					_paq.push(['trackPageView']);
					_paq.push(['enableLinkTracking']);
					(function() {
						var u="//www.stats.numberchallenge.com/";
						_paq.push(['setTrackerUrl', u+'matomo.php']);
						_paq.push(['setSiteId', ${window.Configs.piwik_id}]);
						var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
						g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
					})();
				`}
				</script>
				<noscript>{`<p><img src="${"//www.stats.numberchallenge.com/matomo.php?idsite="+window.Configs.piwik_id}" alt="" /></p>`}</noscript>
				<script src="js/validation.js"></script>
				<script src="https://tools.contrib.com/js/glue.js"></script>
			</Helmet>
		</HelmetProvider>
        <Router>
          <Navigation />
            <Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/about" component={About} />	
				<Route exact path="/apply" component={Apply} />	
				<Route exact path="/apps" component={Apps} />
				<Route exact path="/privacy" component={Privacy} />
				<Route exact path="/terms" component={Terms} />
				<Route exact path="/cookie" component={Cookie} />
				<Route exact path="/developers" component={Developers} />
				<Route exact path="/referral" component={Referral} />
				<Route exact path="/partner" component={Partner} />
            </Switch>
	     <Footer/>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
